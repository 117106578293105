var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"backgroundColor":"rgb(12, 22, 51)","width":"101%"}},[_c('div',{staticClass:"foot_nav",staticStyle:{"width":"1200px","display":"flex","justify-content":"space-between"}},[_c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"foot_column"},[_vm._m(2),_c('router-link',{staticStyle:{"display":"flex"},attrs:{"to":"/dayin"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("报表导出打印方案")])]),_c('router-link',{staticStyle:{"display":"flex"},attrs:{"to":"/qianru"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("报表控件嵌入方案")])]),_c('router-link',{staticStyle:{"display":"flex"},attrs:{"to":"/zhongxin"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("报表中心搭建方案")])])],1),_c('div',{staticClass:"foot_column"},[_vm._m(3),_vm._m(4),_vm._m(5),_c('router-link',{staticStyle:{"display":"flex"},attrs:{"to":"/source"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("开发者资源")])])],1),_vm._m(6),_vm._m(7)]),_vm._m(8)]),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_column"},[_c('a',{attrs:{"href":"javascript:void(0)","target":"_self"}},[_c('p',{staticClass:"foot_nav_main"},[_vm._v("产品中心")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com/screen","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("小智大屏")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com/xzreport","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("小智报表")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com/BI_analysis","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("自助式BI")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_column",staticStyle:{"margin-left":"-50px"}},[_c('a',{staticStyle:{"opacity":"0"},attrs:{"href":"javascript:void(0)","target":"_self"}},[_c('p',{staticClass:"foot_nav_main"},[_vm._v("***")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com/etl","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("数据治理")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com/datapush","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("数据推送")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":" https://www.agileflow.cn/#/homepage","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("安捷云连接器")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)","target":"_self"}},[_c('p',{staticClass:"foot_nav_main"},[_vm._v("产品方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)","target":"_self"}},[_c('p',{staticClass:"foot_nav_main"},[_vm._v("产品下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://github.com/tianzhidata/XZReport","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("Github下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://gitee.com/tizdata_admin/XZReport","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("码云下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_column"},[_c('a',{attrs:{"href":"javascript:void(0)","target":"_self"}},[_c('p',{staticClass:"foot_nav_main"},[_vm._v("关于我们")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("天智官网")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com/cooperation","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("生态合作")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com/join","target":"_blank"}},[_c('div',{staticClass:"dian"}),_c('p',{staticClass:"foot_nav_sub"},[_vm._v("加入我们")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_column"},[_c('a',{attrs:{"href":"javascript:void(0)","target":"_self"}},[_c('p',{staticClass:"foot_nav_main"},[_vm._v("联系我们")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"href":"https://tizdata.com","target":"_blank"}},[_c('p',{staticClass:"foot_nav_sub"},[_vm._v("公司名称：北京天智鲲鹏技术有限公司")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"target":"_blank"}},[_c('p',{staticClass:"foot_nav_sub"},[_vm._v("邮箱地址：zqf@tizdata.com")])]),_c('a',{staticStyle:{"display":"flex"},attrs:{"target":"_blank"}},[_c('p',{staticClass:"foot_nav_sub"},[_vm._v("QQ群号：894709724")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_column",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"ewm"},[_c('p',[_vm._v("天智公众号")]),_c('img',{attrs:{"src":"http://www.tizdata.com/images/rwm01.jpg"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin":"0","padding":"0","color":"#8d9ba3","font-size":"12px","padding-top":"15px","padding-bottom":"15px","line-height":"23px","text-align":"center","background-color":"rgb(9, 17, 39)"},attrs:{"id":"tizdata"}},[_c('a',{staticStyle:{"display":"inline-block","text-decoration":"none","height":"20px","line-height":"20px"},attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302005192"}},[_c('img',{staticStyle:{"float":"left"},attrs:{"src":require("@/assets/image/footer/gongan.png")}}),_c('span',{staticStyle:{"height":"20px","margin":"0px 0px 0px 5px","color":"#8d9ba3"}},[_vm._v("京公网安备 11011302005192号")])]),_vm._v("  "),_c('a',{staticStyle:{"color":"#8d9ba3"},attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("京ICP备20010559号-7")]),_vm._v("    Copyright © 2023-"),_c('span',{staticClass:"current_date"}),_vm._v(" www.xzreport.com All Rights Reserved. ")])
}]

export { render, staticRenderFns }