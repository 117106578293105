<template>
  <div id="app">
    <Header v-show="$route.meta.isShow"></Header>
    <!-- <div class="pos" style="position: relative; text-align: center; margin-top: 200px">
      <el-select 
      v-model="value" 
      :popper-append-to-body="false" 
      filterable 
      placeholder="请选择">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div> -->
    <div><router-view></router-view></div>
    <!-- <vxe-grid border stripe resizable  height="500" :columns="tableColumn" :data="tableData"></vxe-grid> -->
    <Footer v-show="$route.meta.isShow"></Footer>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Header from '@/components/Header/index.vue';
import Footer from '@/components/Footer/index.vue';
// import Home from '@/pages/Home/index/index.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    // Home
  },
  data() {
    return {
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          value: '选项2',
          label: '双皮奶',
        },
        {
          value: '选项3',
          label: '蚵仔煎',
        },
        {
          value: '选项4',
          label: '龙须面',
        },
        {
          value: '选项5',
          label: '北京烤鸭',
        },
      ],
      value: '',
      tableColumn: [
        { type: 'seq', width: 100 },
        {
          title: '基本信息',
          children: [
            { field: 'name', title: 'Name' },
            {
              title: '其他信息',
              children: [
                { field: 'nickname', title: 'Nickname', width: 200 },
                { field: 'age', title: 'Age', sortable: true, width: 200 },
              ],
            },
            { field: 'sex', title: 'Sex', width: 200 },
          ],
        },
        { field: 'address', title: 'Address', sortable: true, showOverflow: true, width: 200 },
      ],
      tableData: [
        { id: 10001, name: 'Test1', nickname: 'T1', role: 'Develop', sex: 'Man', age: 28, address: 'Shenzhen' },
        { id: 10002, name: 'Test2', nickname: 'T2', role: 'Test', sex: 'Women', age: 22, address: 'Guangzhou' },
        { id: 10003, name: 'Test3', nickname: 'T3', role: 'PM', sex: 'Man', age: 32, address: 'Shanghai' },
        { id: 10004, name: 'Test4', nickname: 'T4', role: 'Designer', sex: 'Women ', age: 23, address: 'Shenzhen' },
        { id: 10005, name: 'Test5', nickname: 'T5', role: 'Develop', sex: 'Women ', age: 30, address: 'Shanghai' },
        { id: 10006, name: 'Test6', nickname: 'T6', role: 'Designer', sex: 'Women ', age: 21, address: 'Shenzhen' },
        { id: 10007, name: 'Test7', nickname: 'T7', role: 'Test', sex: 'Man ', age: 29, address: 'Shenzhen' },
        { id: 10008, name: 'Test8', nickname: 'T8', role: 'Develop', sex: 'Man ', age: 35, address: 'Shenzhen' },
      ],
    };
  },
  mounted() {
    // 异步加载筛选数据
    setTimeout(() => {
      const $table = this.$refs.xTable1;
      if ($table) {
        const nameColumn = $table.getColumnByField('name');
        if (nameColumn) {
          $table.setFilter(nameColumn, [
            { label: 'id大于10002', value: 10002 },
            { label: 'id大于10003', value: 10003 },
          ]);
        }
      }
    }, 500);
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        //监听浏览器窗口大小改变
        //浏览器变化执行动作
        function ChangeRatio() {
          var ratio = 0;
          var screen = window.screen;
          var ua = navigator.userAgent.toLowerCase();
          if (window.devicePixelRatio !== undefined) {
            ratio = window.devicePixelRatio;
          } else if (~ua.indexOf('msie')) {
            if (screen.deviceXDPI && screen.logicalXDPI) {
              ratio = screen.deviceXDPI / screen.logicalXDPI;
            }
          } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
            ratio = window.outerWidth / window.innerWidth;
          }
          if (ratio) {
            ratio = Math.round(ratio * 100);
          }
          return ratio;
        }
        this.ratio = ChangeRatio();
        if (this.ratio == '75') {
          console.log('浏览器页面比例为150');
        }
      });
    });
  },
  methods: {
    filterVisibleEvent({ column, visible }) {
      console.log(`${column.property} ${visible ? '打开' : '关闭'}筛选面板`);
    },
    filterChangeEvent({ column }) {
      console.log(`${column.property} 筛选了数据`);
    },
    filterNameMethod({ value, row, column }) {
      return row.id >= value;
    },
    filterAgeMethod({ option, row }) {
      return row.age === Number(option.data);
    },
  },
};
</script>

<style>
/* 对话框偏移问题 */
.el-popup-parent--hidden {
  padding-right: 0px !important;
  overflow: hidden !important;
}
</style>
