import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import './config/rem'
// import VXETable from 'vxe-table'
import 'xe-utils'
import VXETable from '../vxe-table'
import '../vxe-table/styles/variable.scss'
import "animate.css";
Vue.use(ElementUI);
Vue.use(VXETable);

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined?'小智报表':to.meta.title
    if (to.meta.requireAuth) {
        let token = Cookies.get('access_token');
        let anonymous = Cookies.get('user_name');
        if (token) {            
                next({
                    path: '/',
                    query: {
                        redirect: to.fullPath
                    } 
                })         
        }
    }
    next();
})

import router from '@/router/index.js'
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')





