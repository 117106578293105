<template>
  <div style="backgroundColor:rgb(12, 22, 51);width:101%">
    <div class="foot_nav" style="width: 1200px;display: flex;justify-content: space-between;">
      <div>
        <div class="foot_column">
          <a href="javascript:void(0)" target="_self">
            <p class="foot_nav_main">产品中心</p>
          </a>
          <a href="https://tizdata.com/screen" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">小智大屏</p>
          </a>
          <a href="https://tizdata.com/xzreport" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">小智报表</p>
          </a>
          <a href="https://tizdata.com/BI_analysis" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">自助式BI</p>
          </a>
        </div>
        <div class="foot_column" style="margin-left: -50px;">
          <a href="javascript:void(0)" target="_self" style="opacity: 0;">
            <p class="foot_nav_main">***</p>
          </a>
          <a href="https://tizdata.com/etl" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">数据治理</p>
          </a>
          <a href="https://tizdata.com/datapush" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">数据推送</p>
          </a>
          <a href=" https://www.agileflow.cn/#/homepage" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">安捷云连接器</p>
          </a>
        </div>
        <div class="foot_column">
          <a href="javascript:void(0)" target="_self">
            <p class="foot_nav_main">产品方案</p>
          </a>
          <router-link to="/dayin" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">报表导出打印方案</p>
          </router-link>
          <router-link to="/qianru" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">报表控件嵌入方案</p>
          </router-link>
          <router-link to="/zhongxin" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">报表中心搭建方案</p>
          </router-link>
        </div>
        <div class="foot_column">
          <a href="javascript:void(0)" target="_self">
            <p class="foot_nav_main">产品下载</p>
          </a>
          <a href="https://github.com/tianzhidata/XZReport" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">Github下载</p>
          </a>
          <a href="https://gitee.com/tizdata_admin/XZReport" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">码云下载</p>
          </a>
          <router-link to="/source" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">开发者资源</p>
          </router-link>
        </div>
        <div class="foot_column">
          <a href="javascript:void(0)" target="_self">
            <p class="foot_nav_main">关于我们</p>
          </a>
          <a href="https://tizdata.com" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">天智官网</p>
          </a>
          <a href="https://tizdata.com/cooperation" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">生态合作</p>
          </a>
          <a href="https://tizdata.com/join" target="_blank" style="display: flex;">
            <div class="dian"></div>
            <p class="foot_nav_sub">加入我们</p>
          </a>
        </div>
        <div class="foot_column">
          <a href="javascript:void(0)" target="_self">
            <p class="foot_nav_main">联系我们</p>
          </a>
          <a href="https://tizdata.com" target="_blank" style="display: flex;">
            <p class="foot_nav_sub">公司名称：北京天智鲲鹏技术有限公司</p>
          </a>
          <a  target="_blank" style="display: flex;">
            <p class="foot_nav_sub">邮箱地址：zqf@tizdata.com</p>
          </a>
          <a  target="_blank" style="display: flex;">
            <p class="foot_nav_sub">QQ群号：894709724</p>
          </a>
        </div>
      </div>

      <div class="foot_column" style="text-align: center;">
        <div class="ewm">
          <p>天智公众号</p>
          <img src="http://www.tizdata.com/images/rwm01.jpg" />
        </div>
      </div>
    </div>
    <p id="tizdata"
      style="margin: 0;padding: 0;color: #8d9ba3;font-size: 12px;padding-top: 15px;padding-bottom: 15px;;line-height: 23px;text-align: center;background-color: rgb(9, 17, 39);">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302005192"
        style="display: inline-block;text-decoration: none;height: 20px;line-height: 20px;"><img
          src="~@/assets/image/footer/gongan.png" style="float: left" />
        <span style="height: 20px; margin: 0px 0px 0px 5px; color: #8d9ba3">京公网安备 11011302005192号</span></a>&nbsp; 
      <a style="color: #8d9ba3" href="https://beian.miit.gov.cn" target="_blank">京ICP备20010559号-7</a>&nbsp;&nbsp;&nbsp;
      Copyright © 2023-<span class="current_date"></span> www.xzreport.com All
      Rights Reserved.
    </p>


  </div>
</template>

<script>
export default {

}
</script>

<style>
template {
  font-family: 'PingFang SC' !important;
}

.dian {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 7px;
  background: #e0e0e0;
  margin-top: 4px;
}

/*尾部的公共样式*/
.footer {
  margin-top: 130px;
  background: #2e3033;
  font-family: 'PingFang SC' !important;
}

/* display: none; */
.foot_nav {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
}

.foot_column {
  float: left;
  margin-right: 88px;
}

.foot_column:last-child {
  margin-right: 0px;
}

.foot_column a {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.foot_column a p {
  margin: 0;
  padding: 0;
}

.foot_column a .foot_nav_main {
  color: #ffffff;
  font-size: 20px;
  padding-top: 35px;
  padding-bottom: 27px;
}

.foot_column a .foot_nav_sub {
  color: #e0e0e0;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 20px;
}

.foot_column .download {
  white-space: nowrap;
}

.foot_column .download img {
  white-space: nowrap;
  height: 36px;
  margin-right: 40px;
}

.foot_column .download img:last-child {
  margin-right: 0;
}

.foot_column .ewm img {
  height: 80px;
  margin-top: 30px;
}

.foot_column .ewm p {
  margin: 0;
  padding: 0;
  margin-top: 42px;
  color: #e0e0e0;
  font-size: 20px;
  line-height: 1;
  text-align: left;
}</style>