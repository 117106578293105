import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
// const Home = ()=>import('@/pages/Home/index/index')
const About = ()=>import('@/pages/About/index')
const Video = ()=>import('@/pages/Video/index')
const Resour = ()=>import('@/pages/Resour/index')
const Interact = ()=>import('@/pages/Interact/index')
const Chatgroup = ()=>import('@/pages/Chatgroup/index')
const Again = ()=>import('@/pages/Home/Again/index')
// const Price = ()=>import('@/pages/Home/Price/index')
const Price = ()=> import('../pages/Price')
const Programme = ()=>import('@/pages/Xzreport/Programme')
const Print = ()=>import('@/pages/Xzreport/Print')
const Seek = ()=>import('@/pages/Xzreport/Seek')
const Key = ()=>import('@/pages/Xzreport/Key')
const Build = ()=>import('@/pages/Xzreport/Build')
let BaseTitle = '报表工具,小智报表,报表控件,嵌入式报表工具,开源免费,打印报表控件'
const routes = [
    {
        path: '/',
        component: Again,
        meta: {
            title: `${BaseTitle}-首页`,    
            keepAlive: true, // 需要被缓存
            isShow:true
        }
    },
    {
        path: '/qianru',
        component: Programme,
        meta: {
            title: `${BaseTitle}-嵌入`,    
            keepAlive: true, // 需要被缓存
            isShow:true
        }
    },
    {
        path: '/dayin',
        component: Print,
        meta: {
            title: `${BaseTitle}-打印`,    
            keepAlive: true, // 需要被缓存
            isShow:true
        }
    },
    {
        path: '/zhongxin',
        component: Build,
        meta: {
            title: `${BaseTitle}-中心搭建`,    
            keepAlive: true, // 需要被缓存
            isShow:true
        }
    },
    {
        path: '/vip',
        component: Price,
        meta: {
            title: `${BaseTitle}-产品定价`,    
            keepAlive: true, // 需要被缓存
            isShow:true
        }
    },
    {
        path: '/seek',
        component: Seek,
        meta: {
            title: `${BaseTitle}-购买咨询`,    
            keepAlive: true, // 需要被缓存
            isShow:true
        }
    },
    {
        path: '/key',
        component: Key,
        meta: {
            title: `${BaseTitle}-密钥获取`,    
            keepAlive: true, // 需要被缓存
            isShow:true
        }
    },
    {
        path: '/about',
        component:About,
        meta: {
            title: `${BaseTitle}-关于我们`,
            isShow:true
        }       
    },
    {
        path: '/course',
        component:Video,
        meta: {
            title: `${BaseTitle}-视频教学`,
            isShow:true
        }      
    },
    {
        path: '/source',
        component:Resour,
        meta: {
            title: `${BaseTitle}-开发者资源`,
            isShow:true
        }
        
    },
    {
        path: '/interact',
        component:Interact,
        meta: {
            title: `${BaseTitle}-生态合作`,
            isShow:true
        }
        
    },
    {
        path: '/chatgroup',
        component:Chatgroup ,
        meta: {
            title: `${BaseTitle}-交流`,
            isShow:true
        }      
    },
    {
        path: '/404',
        name: 'NotFound',
        meta: {
          title: '404',
          isShow:false
        },
        component: () => import('@/pages/404')
      },
      // 所有未定义路由，全部重定向到404页
      {
        path: '*',
        redirect: '/404'
      }
    // {
    //     path: '/option',
    //     component:Option,
    //     meta: {
    //         title: '意见反馈',
    //         keepAlive: false, // 需要被缓存
    //     }     
    // },

]


const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        //滚动行为这个函数,需要有返回值,返回值为一个对象。
        //经常可以设置滚动条x|y位置 [x|y数值的设置一般最小是零]
        return { y: 0 };
    }
})

export default router